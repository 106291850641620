/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import * as i0 from "@angular/core";
/**
 * Global configuration
 */
export class ApiConfiguration {
  rootUrl = 'http://localhost:3000';
  static ɵfac = function ApiConfiguration_Factory(t) {
    return new (t || ApiConfiguration)();
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: ApiConfiguration,
    factory: ApiConfiguration.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ApiConfiguration, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();

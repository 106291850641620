/* tslint:disable */
/* eslint-disable */
export var BandmanagerRestApiUniformPartValueEnum;
(function (BandmanagerRestApiUniformPartValueEnum) {
  BandmanagerRestApiUniformPartValueEnum["HatNone"] = "HatNone";
  BandmanagerRestApiUniformPartValueEnum["HatBeret"] = "HatBeret";
  BandmanagerRestApiUniformPartValueEnum["HatBaseball"] = "HatBaseball";
  BandmanagerRestApiUniformPartValueEnum["HatTop"] = "HatTop";
  BandmanagerRestApiUniformPartValueEnum["TieNone"] = "TieNone";
  BandmanagerRestApiUniformPartValueEnum["TieTie"] = "TieTie";
  BandmanagerRestApiUniformPartValueEnum["TieBowtie"] = "TieBowtie";
  BandmanagerRestApiUniformPartValueEnum["JacketNone"] = "JacketNone";
  BandmanagerRestApiUniformPartValueEnum["JacketUnicolor"] = "JacketUnicolor";
  BandmanagerRestApiUniformPartValueEnum["JacketBicolor"] = "JacketBicolor";
  BandmanagerRestApiUniformPartValueEnum["ShirtShort"] = "ShirtShort";
  BandmanagerRestApiUniformPartValueEnum["ShirtLong"] = "ShirtLong";
  BandmanagerRestApiUniformPartValueEnum["PantsShort"] = "PantsShort";
  BandmanagerRestApiUniformPartValueEnum["PantsLong"] = "PantsLong";
  BandmanagerRestApiUniformPartValueEnum["ShoesClassic"] = "ShoesClassic";
  BandmanagerRestApiUniformPartValueEnum["ShoesSneaker"] = "ShoesSneaker";
})(BandmanagerRestApiUniformPartValueEnum || (BandmanagerRestApiUniformPartValueEnum = {}));

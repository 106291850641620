/* tslint:disable */
/* eslint-disable */
export var BandmanagerRestApiUniformPartTypeEnum;
(function (BandmanagerRestApiUniformPartTypeEnum) {
  BandmanagerRestApiUniformPartTypeEnum["Hat"] = "hat";
  BandmanagerRestApiUniformPartTypeEnum["Tie"] = "tie";
  BandmanagerRestApiUniformPartTypeEnum["Jacket"] = "jacket";
  BandmanagerRestApiUniformPartTypeEnum["Shirt"] = "shirt";
  BandmanagerRestApiUniformPartTypeEnum["Pants"] = "pants";
  BandmanagerRestApiUniformPartTypeEnum["Shoes"] = "shoes";
})(BandmanagerRestApiUniformPartTypeEnum || (BandmanagerRestApiUniformPartTypeEnum = {}));

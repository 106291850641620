/* tslint:disable */
/* eslint-disable */
import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { filter, map } from 'rxjs/operators';
import { BaseService } from '../base-service';
import { RequestBuilder } from '../request-builder';
import * as i0 from "@angular/core";
import * as i1 from "../api-configuration";
import * as i2 from "@angular/common/http";
export class BandmanagerRestApiMyEventsService extends BaseService {
  constructor(config, http) {
    super(config, http);
  }
  /** Path part for operation `eventParticipationsControllerGetMyEventParticipations()` */
  static EventParticipationsControllerGetMyEventParticipationsPath = '/v1/content-management-api/event-participations';
  /**
   * Get my event participations.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `eventParticipationsControllerGetMyEventParticipations()` instead.
   *
   * This method doesn't expect any request body.
   */
  eventParticipationsControllerGetMyEventParticipations$Response(params, context) {
    const rb = new RequestBuilder(this.rootUrl, BandmanagerRestApiMyEventsService.EventParticipationsControllerGetMyEventParticipationsPath, 'get');
    if (params) {}
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context
    })).pipe(filter(r => r instanceof HttpResponse), map(r => {
      return r;
    }));
  }
  /**
   * Get my event participations.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `eventParticipationsControllerGetMyEventParticipations$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  eventParticipationsControllerGetMyEventParticipations(params, context) {
    return this.eventParticipationsControllerGetMyEventParticipations$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `eventParticipationsControllerRegisterForEvent()` */
  static EventParticipationsControllerRegisterForEventPath = '/v1/content-management-api/event-participations';
  /**
   * Register for an event/create an event participation.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `eventParticipationsControllerRegisterForEvent()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  eventParticipationsControllerRegisterForEvent$Response(params, context) {
    const rb = new RequestBuilder(this.rootUrl, BandmanagerRestApiMyEventsService.EventParticipationsControllerRegisterForEventPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context
    })).pipe(filter(r => r instanceof HttpResponse), map(r => {
      return r;
    }));
  }
  /**
   * Register for an event/create an event participation.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `eventParticipationsControllerRegisterForEvent$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  eventParticipationsControllerRegisterForEvent(params, context) {
    return this.eventParticipationsControllerRegisterForEvent$Response(params, context).pipe(map(r => r.body));
  }
  static ɵfac = function BandmanagerRestApiMyEventsService_Factory(t) {
    return new (t || BandmanagerRestApiMyEventsService)(i0.ɵɵinject(i1.ApiConfiguration), i0.ɵɵinject(i2.HttpClient));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: BandmanagerRestApiMyEventsService,
    factory: BandmanagerRestApiMyEventsService.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BandmanagerRestApiMyEventsService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1.ApiConfiguration
  }, {
    type: i2.HttpClient
  }], null);
})();

/* tslint:disable */
/* eslint-disable */
export var BandmanagerRestApiSectionEnum;
(function (BandmanagerRestApiSectionEnum) {
  BandmanagerRestApiSectionEnum["Championship"] = "Championship";
  BandmanagerRestApiSectionEnum["First"] = "First";
  BandmanagerRestApiSectionEnum["Second"] = "Second";
  BandmanagerRestApiSectionEnum["Third"] = "Third";
  BandmanagerRestApiSectionEnum["Fourth"] = "Fourth";
})(BandmanagerRestApiSectionEnum || (BandmanagerRestApiSectionEnum = {}));

/* tslint:disable */
/* eslint-disable */
export var BandmanagerRestApiBandPositionEnum;
(function (BandmanagerRestApiBandPositionEnum) {
  BandmanagerRestApiBandPositionEnum["Unassigned"] = "Unassigned";
  BandmanagerRestApiBandPositionEnum["Conductor"] = "Conductor";
  BandmanagerRestApiBandPositionEnum["SopranoCornet"] = "SopranoCornet";
  BandmanagerRestApiBandPositionEnum["PrincipalCornet"] = "PrincipalCornet";
  BandmanagerRestApiBandPositionEnum["AssistantCornet"] = "AssistantCornet";
  BandmanagerRestApiBandPositionEnum["Solocornet3"] = "Solocornet3";
  BandmanagerRestApiBandPositionEnum["Solocornet4"] = "Solocornet4";
  BandmanagerRestApiBandPositionEnum["RepianoCornet"] = "RepianoCornet";
  BandmanagerRestApiBandPositionEnum["SecondCornet2"] = "SecondCornet2";
  BandmanagerRestApiBandPositionEnum["SecondCornet1"] = "SecondCornet1";
  BandmanagerRestApiBandPositionEnum["ThirdCornet1"] = "ThirdCornet1";
  BandmanagerRestApiBandPositionEnum["ThirdCornet2"] = "ThirdCornet2";
  BandmanagerRestApiBandPositionEnum["Flugelhorn"] = "Flugelhorn";
  BandmanagerRestApiBandPositionEnum["Solohorn"] = "Solohorn";
  BandmanagerRestApiBandPositionEnum["FirstHorn"] = "FirstHorn";
  BandmanagerRestApiBandPositionEnum["SecondHorn"] = "SecondHorn";
  BandmanagerRestApiBandPositionEnum["FirstBaritone"] = "FirstBaritone";
  BandmanagerRestApiBandPositionEnum["SecondBaritone"] = "SecondBaritone";
  BandmanagerRestApiBandPositionEnum["SoloEuphonium"] = "SoloEuphonium";
  BandmanagerRestApiBandPositionEnum["SecondEuphonium"] = "SecondEuphonium";
  BandmanagerRestApiBandPositionEnum["SoloTrombone"] = "SoloTrombone";
  BandmanagerRestApiBandPositionEnum["SecondTrombone"] = "SecondTrombone";
  BandmanagerRestApiBandPositionEnum["BassTrombone"] = "BassTrombone";
  BandmanagerRestApiBandPositionEnum["SoloEbTuba"] = "SoloEbTuba";
  BandmanagerRestApiBandPositionEnum["SecondEbTuba"] = "SecondEbTuba";
  BandmanagerRestApiBandPositionEnum["SoloBbTuba"] = "SoloBbTuba";
  BandmanagerRestApiBandPositionEnum["SecondBbTuba"] = "SecondBbTuba";
  BandmanagerRestApiBandPositionEnum["Percussion1"] = "Percussion1";
  BandmanagerRestApiBandPositionEnum["Percussion2"] = "Percussion2";
  BandmanagerRestApiBandPositionEnum["Percussion3"] = "Percussion3";
  BandmanagerRestApiBandPositionEnum["Percussion4"] = "Percussion4";
})(BandmanagerRestApiBandPositionEnum || (BandmanagerRestApiBandPositionEnum = {}));

/* tslint:disable */
/* eslint-disable */
import { NgModule, SkipSelf, Optional } from '@angular/core';
import { ApiConfiguration } from './api-configuration';
import { BandmanagerRestApiSystemService } from './services/bandmanager-rest-api-system.service';
import { BandmanagerRestApiAppleService } from './services/bandmanager-rest-api-apple.service';
import { BandmanagerRestApiAuthenticationService } from './services/bandmanager-rest-api-authentication.service';
import { BandmanagerRestApiMyBandService } from './services/bandmanager-rest-api-my-band.service';
import { BandmanagerRestApiMyEventsService } from './services/bandmanager-rest-api-my-events.service';
import { BandmanagerRestApiMyDevicesService } from './services/bandmanager-rest-api-my-devices.service';
import { BandmanagerRestApiAllBandsService } from './services/bandmanager-rest-api-all-bands.service';
import { BandmanagerRestApiAllCountriesService } from './services/bandmanager-rest-api-all-countries.service';
import { BandmanagerRestApiStatsService } from './services/bandmanager-rest-api-stats.service';
import { BandmanagerRestApiAllPlayersService } from './services/bandmanager-rest-api-all-players.service';
import { BandmanagerRestApiAllEventsService } from './services/bandmanager-rest-api-all-events.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
/**
 * Module that provides all services and configuration.
 */
export class ApiModule {
  static forRoot(params) {
    return {
      ngModule: ApiModule,
      providers: [{
        provide: ApiConfiguration,
        useValue: params
      }]
    };
  }
  constructor(parentModule, http) {
    if (parentModule) {
      throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' + 'See also https://github.com/angular/angular/issues/20575');
    }
  }
  static ɵfac = function ApiModule_Factory(t) {
    return new (t || ApiModule)(i0.ɵɵinject(ApiModule, 12), i0.ɵɵinject(i1.HttpClient, 8));
  };
  static ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
    type: ApiModule
  });
  static ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
    providers: [BandmanagerRestApiSystemService, BandmanagerRestApiAppleService, BandmanagerRestApiAuthenticationService, BandmanagerRestApiMyBandService, BandmanagerRestApiMyEventsService, BandmanagerRestApiMyDevicesService, BandmanagerRestApiAllBandsService, BandmanagerRestApiAllCountriesService, BandmanagerRestApiStatsService, BandmanagerRestApiAllPlayersService, BandmanagerRestApiAllEventsService, ApiConfiguration]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ApiModule, [{
    type: NgModule,
    args: [{
      imports: [],
      exports: [],
      declarations: [],
      providers: [BandmanagerRestApiSystemService, BandmanagerRestApiAppleService, BandmanagerRestApiAuthenticationService, BandmanagerRestApiMyBandService, BandmanagerRestApiMyEventsService, BandmanagerRestApiMyDevicesService, BandmanagerRestApiAllBandsService, BandmanagerRestApiAllCountriesService, BandmanagerRestApiStatsService, BandmanagerRestApiAllPlayersService, BandmanagerRestApiAllEventsService, ApiConfiguration]
    }]
  }], () => [{
    type: ApiModule,
    decorators: [{
      type: Optional
    }, {
      type: SkipSelf
    }]
  }, {
    type: i1.HttpClient,
    decorators: [{
      type: Optional
    }]
  }], null);
})();

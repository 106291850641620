/* tslint:disable */
/* eslint-disable */
import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { filter, map } from 'rxjs/operators';
import { BaseService } from '../base-service';
import { RequestBuilder } from '../request-builder';
import * as i0 from "@angular/core";
import * as i1 from "../api-configuration";
import * as i2 from "@angular/common/http";
export class BandmanagerRestApiAllPlayersService extends BaseService {
  constructor(config, http) {
    super(config, http);
  }
  /** Path part for operation `playersDeliveryControllerGetById()` */
  static PlayersDeliveryControllerGetByIdPath = '/v1/content-delivery-api/players/{id}';
  /**
   * Get a specific player by ID.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `playersDeliveryControllerGetById()` instead.
   *
   * This method doesn't expect any request body.
   */
  playersDeliveryControllerGetById$Response(params, context) {
    const rb = new RequestBuilder(this.rootUrl, BandmanagerRestApiAllPlayersService.PlayersDeliveryControllerGetByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context
    })).pipe(filter(r => r instanceof HttpResponse), map(r => {
      return r;
    }));
  }
  /**
   * Get a specific player by ID.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `playersDeliveryControllerGetById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  playersDeliveryControllerGetById(params, context) {
    return this.playersDeliveryControllerGetById$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `playersDeliveryControllerGetAllPaginated()` */
  static PlayersDeliveryControllerGetAllPaginatedPath = '/v1/content-delivery-api/players';
  /**
   * Get all players paginated and ordered by points.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `playersDeliveryControllerGetAllPaginated()` instead.
   *
   * This method doesn't expect any request body.
   */
  playersDeliveryControllerGetAllPaginated$Response(params, context) {
    const rb = new RequestBuilder(this.rootUrl, BandmanagerRestApiAllPlayersService.PlayersDeliveryControllerGetAllPaginatedPath, 'get');
    if (params) {
      rb.query('skip', params.skip, {});
      rb.query('take', params.take, {});
      rb.query('countryId', params.countryId, {});
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context
    })).pipe(filter(r => r instanceof HttpResponse), map(r => {
      return r;
    }));
  }
  /**
   * Get all players paginated and ordered by points.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `playersDeliveryControllerGetAllPaginated$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  playersDeliveryControllerGetAllPaginated(params, context) {
    return this.playersDeliveryControllerGetAllPaginated$Response(params, context).pipe(map(r => r.body));
  }
  static ɵfac = function BandmanagerRestApiAllPlayersService_Factory(t) {
    return new (t || BandmanagerRestApiAllPlayersService)(i0.ɵɵinject(i1.ApiConfiguration), i0.ɵɵinject(i2.HttpClient));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: BandmanagerRestApiAllPlayersService,
    factory: BandmanagerRestApiAllPlayersService.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BandmanagerRestApiAllPlayersService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1.ApiConfiguration
  }, {
    type: i2.HttpClient
  }], null);
})();

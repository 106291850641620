/* tslint:disable */
/* eslint-disable */
import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { filter, map } from 'rxjs/operators';
import { BaseService } from '../base-service';
import { RequestBuilder } from '../request-builder';
import * as i0 from "@angular/core";
import * as i1 from "../api-configuration";
import * as i2 from "@angular/common/http";
export class BandmanagerRestApiMyBandService extends BaseService {
  constructor(config, http) {
    super(config, http);
  }
  /** Path part for operation `bandControllerGetMyBand()` */
  static BandControllerGetMyBandPath = '/v1/content-management-api/band';
  /**
   * Get my band.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `bandControllerGetMyBand()` instead.
   *
   * This method doesn't expect any request body.
   */
  bandControllerGetMyBand$Response(params, context) {
    const rb = new RequestBuilder(this.rootUrl, BandmanagerRestApiMyBandService.BandControllerGetMyBandPath, 'get');
    if (params) {}
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context
    })).pipe(filter(r => r instanceof HttpResponse), map(r => {
      return r;
    }));
  }
  /**
   * Get my band.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `bandControllerGetMyBand$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  bandControllerGetMyBand(params, context) {
    return this.bandControllerGetMyBand$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `bandControllerCreateBand()` */
  static BandControllerCreateBandPath = '/v1/content-management-api/band';
  /**
   * Create a new Band.
   *
   * When a new band is generated, its "bandStatus" is "New". It automatically triggers the generation of random players including skills. During this generation, "bandStatus" is "Generating", and when finished it will be "Ready". This should take no longer than a few seconds. One user can only have one band.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `bandControllerCreateBand()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  bandControllerCreateBand$Response(params, context) {
    const rb = new RequestBuilder(this.rootUrl, BandmanagerRestApiMyBandService.BandControllerCreateBandPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context
    })).pipe(filter(r => r instanceof HttpResponse), map(r => {
      return r;
    }));
  }
  /**
   * Create a new Band.
   *
   * When a new band is generated, its "bandStatus" is "New". It automatically triggers the generation of random players including skills. During this generation, "bandStatus" is "Generating", and when finished it will be "Ready". This should take no longer than a few seconds. One user can only have one band.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `bandControllerCreateBand$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  bandControllerCreateBand(params, context) {
    return this.bandControllerCreateBand$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `bandControllerGetMyBandStats()` */
  static BandControllerGetMyBandStatsPath = '/v1/content-management-api/band/stats';
  /**
   * Get my band stats.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `bandControllerGetMyBandStats()` instead.
   *
   * This method doesn't expect any request body.
   */
  bandControllerGetMyBandStats$Response(params, context) {
    const rb = new RequestBuilder(this.rootUrl, BandmanagerRestApiMyBandService.BandControllerGetMyBandStatsPath, 'get');
    if (params) {}
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context
    })).pipe(filter(r => r instanceof HttpResponse), map(r => {
      return r;
    }));
  }
  /**
   * Get my band stats.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `bandControllerGetMyBandStats$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  bandControllerGetMyBandStats(params, context) {
    return this.bandControllerGetMyBandStats$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `bandControllerStartRehearsal()` */
  static BandControllerStartRehearsalPath = '/v1/content-management-api/band/startRehearsal';
  /**
   * Start a new rehearsal.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `bandControllerStartRehearsal()` instead.
   *
   * This method doesn't expect any request body.
   */
  bandControllerStartRehearsal$Response(params, context) {
    const rb = new RequestBuilder(this.rootUrl, BandmanagerRestApiMyBandService.BandControllerStartRehearsalPath, 'get');
    if (params) {}
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context
    })).pipe(filter(r => r instanceof HttpResponse), map(r => {
      return r;
    }));
  }
  /**
   * Start a new rehearsal.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `bandControllerStartRehearsal$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  bandControllerStartRehearsal(params, context) {
    return this.bandControllerStartRehearsal$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `bankaccountControllerGetMyBand()` */
  static BankaccountControllerGetMyBandPath = '/v1/content-management-api/bankaccount';
  /**
   * Get my bank account.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `bankaccountControllerGetMyBand()` instead.
   *
   * This method doesn't expect any request body.
   */
  bankaccountControllerGetMyBand$Response(params, context) {
    const rb = new RequestBuilder(this.rootUrl, BandmanagerRestApiMyBandService.BankaccountControllerGetMyBandPath, 'get');
    if (params) {}
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context
    })).pipe(filter(r => r instanceof HttpResponse), map(r => {
      return r;
    }));
  }
  /**
   * Get my bank account.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `bankaccountControllerGetMyBand$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  bankaccountControllerGetMyBand(params, context) {
    return this.bankaccountControllerGetMyBand$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `logoControllerCreate()` */
  static LogoControllerCreatePath = '/v1/content-management-api/logo';
  /**
   * Upload logo for band.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `logoControllerCreate()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  logoControllerCreate$Response(params, context) {
    const rb = new RequestBuilder(this.rootUrl, BandmanagerRestApiMyBandService.LogoControllerCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context
    })).pipe(filter(r => r instanceof HttpResponse), map(r => {
      return r;
    }));
  }
  /**
   * Upload logo for band.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `logoControllerCreate$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  logoControllerCreate(params, context) {
    return this.logoControllerCreate$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `logoControllerRemove()` */
  static LogoControllerRemovePath = '/v1/content-management-api/logo';
  /**
   * Delete logo of the band.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `logoControllerRemove()` instead.
   *
   * This method doesn't expect any request body.
   */
  logoControllerRemove$Response(params, context) {
    const rb = new RequestBuilder(this.rootUrl, BandmanagerRestApiMyBandService.LogoControllerRemovePath, 'delete');
    if (params) {}
    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context
    })).pipe(filter(r => r instanceof HttpResponse), map(r => {
      return r.clone({
        body: undefined
      });
    }));
  }
  /**
   * Delete logo of the band.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `logoControllerRemove$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  logoControllerRemove(params, context) {
    return this.logoControllerRemove$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `logoControllerView()` */
  static LogoControllerViewPath = '/v1/content-management-api/logo/{fileRequestId}/{fileName}';
  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `logoControllerView()` instead.
   *
   * This method doesn't expect any request body.
   */
  logoControllerView$Response(params, context) {
    const rb = new RequestBuilder(this.rootUrl, BandmanagerRestApiMyBandService.LogoControllerViewPath, 'get');
    if (params) {
      rb.path('fileRequestId', params.fileRequestId, {});
      rb.path('fileName', params.fileName, {});
    }
    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context
    })).pipe(filter(r => r instanceof HttpResponse), map(r => {
      return r.clone({
        body: undefined
      });
    }));
  }
  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `logoControllerView$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  logoControllerView(params, context) {
    return this.logoControllerView$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `playersControllerGetMyBand()` */
  static PlayersControllerGetMyBandPath = '/v1/content-management-api/players';
  /**
   * Get all players of my band including their rank.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `playersControllerGetMyBand()` instead.
   *
   * This method doesn't expect any request body.
   */
  playersControllerGetMyBand$Response(params, context) {
    const rb = new RequestBuilder(this.rootUrl, BandmanagerRestApiMyBandService.PlayersControllerGetMyBandPath, 'get');
    if (params) {}
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context
    })).pipe(filter(r => r instanceof HttpResponse), map(r => {
      return r;
    }));
  }
  /**
   * Get all players of my band including their rank.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `playersControllerGetMyBand$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  playersControllerGetMyBand(params, context) {
    return this.playersControllerGetMyBand$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `playersControllerUpdate()` */
  static PlayersControllerUpdatePath = '/v1/content-management-api/players/{id}/skills';
  /**
   * Update a players skills.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `playersControllerUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  playersControllerUpdate$Response(params, context) {
    const rb = new RequestBuilder(this.rootUrl, BandmanagerRestApiMyBandService.PlayersControllerUpdatePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context
    })).pipe(filter(r => r instanceof HttpResponse), map(r => {
      return r;
    }));
  }
  /**
   * Update a players skills.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `playersControllerUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  playersControllerUpdate(params, context) {
    return this.playersControllerUpdate$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `uniformControllerGetMyBand()` */
  static UniformControllerGetMyBandPath = '/v1/content-management-api/uniform';
  /**
   * Get my uniform.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `uniformControllerGetMyBand()` instead.
   *
   * This method doesn't expect any request body.
   */
  uniformControllerGetMyBand$Response(params, context) {
    const rb = new RequestBuilder(this.rootUrl, BandmanagerRestApiMyBandService.UniformControllerGetMyBandPath, 'get');
    if (params) {}
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context
    })).pipe(filter(r => r instanceof HttpResponse), map(r => {
      return r;
    }));
  }
  /**
   * Get my uniform.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `uniformControllerGetMyBand$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  uniformControllerGetMyBand(params, context) {
    return this.uniformControllerGetMyBand$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `uniformControllerUpdateUniform()` */
  static UniformControllerUpdateUniformPath = '/v1/content-management-api/uniform';
  /**
   * Update the uniform.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `uniformControllerUpdateUniform()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  uniformControllerUpdateUniform$Response(params, context) {
    const rb = new RequestBuilder(this.rootUrl, BandmanagerRestApiMyBandService.UniformControllerUpdateUniformPath, 'patch');
    if (params) {
      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context
    })).pipe(filter(r => r instanceof HttpResponse), map(r => {
      return r;
    }));
  }
  /**
   * Update the uniform.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `uniformControllerUpdateUniform$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  uniformControllerUpdateUniform(params, context) {
    return this.uniformControllerUpdateUniform$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `uniformControllerGetUniformPartValuesForUniformPartType()` */
  static UniformControllerGetUniformPartValuesForUniformPartTypePath = '/v1/content-management-api/uniform/uniformPartValuesForUniformPartType/{uniformPartType}';
  /**
   * Get my uniform.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `uniformControllerGetUniformPartValuesForUniformPartType()` instead.
   *
   * This method doesn't expect any request body.
   */
  uniformControllerGetUniformPartValuesForUniformPartType$Response(params, context) {
    const rb = new RequestBuilder(this.rootUrl, BandmanagerRestApiMyBandService.UniformControllerGetUniformPartValuesForUniformPartTypePath, 'get');
    if (params) {
      rb.path('uniformPartType', params.uniformPartType, {});
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context
    })).pipe(filter(r => r instanceof HttpResponse), map(r => {
      return r;
    }));
  }
  /**
   * Get my uniform.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `uniformControllerGetUniformPartValuesForUniformPartType$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  uniformControllerGetUniformPartValuesForUniformPartType(params, context) {
    return this.uniformControllerGetUniformPartValuesForUniformPartType$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `uniformControllerUpdateUniformPart()` */
  static UniformControllerUpdateUniformPartPath = '/v1/content-management-api/uniform/parts';
  /**
   * Update a uniform part.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `uniformControllerUpdateUniformPart()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  uniformControllerUpdateUniformPart$Response(params, context) {
    const rb = new RequestBuilder(this.rootUrl, BandmanagerRestApiMyBandService.UniformControllerUpdateUniformPartPath, 'patch');
    if (params) {
      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context
    })).pipe(filter(r => r instanceof HttpResponse), map(r => {
      return r;
    }));
  }
  /**
   * Update a uniform part.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `uniformControllerUpdateUniformPart$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  uniformControllerUpdateUniformPart(params, context) {
    return this.uniformControllerUpdateUniformPart$Response(params, context).pipe(map(r => r.body));
  }
  static ɵfac = function BandmanagerRestApiMyBandService_Factory(t) {
    return new (t || BandmanagerRestApiMyBandService)(i0.ɵɵinject(i1.ApiConfiguration), i0.ɵɵinject(i2.HttpClient));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: BandmanagerRestApiMyBandService,
    factory: BandmanagerRestApiMyBandService.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BandmanagerRestApiMyBandService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1.ApiConfiguration
  }, {
    type: i2.HttpClient
  }], null);
})();

/* tslint:disable */
/* eslint-disable */
import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { filter, map } from 'rxjs/operators';
import { BaseService } from '../base-service';
import { RequestBuilder } from '../request-builder';
import * as i0 from "@angular/core";
import * as i1 from "../api-configuration";
import * as i2 from "@angular/common/http";
export class BandmanagerRestApiAllBandsService extends BaseService {
  constructor(config, http) {
    super(config, http);
  }
  /** Path part for operation `bandsDeliveryControllerGetById()` */
  static BandsDeliveryControllerGetByIdPath = '/v1/content-delivery-api/bands/{id}';
  /**
   * Get a specific band by ID.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `bandsDeliveryControllerGetById()` instead.
   *
   * This method doesn't expect any request body.
   */
  bandsDeliveryControllerGetById$Response(params, context) {
    const rb = new RequestBuilder(this.rootUrl, BandmanagerRestApiAllBandsService.BandsDeliveryControllerGetByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context
    })).pipe(filter(r => r instanceof HttpResponse), map(r => {
      return r;
    }));
  }
  /**
   * Get a specific band by ID.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `bandsDeliveryControllerGetById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  bandsDeliveryControllerGetById(params, context) {
    return this.bandsDeliveryControllerGetById$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `bandsDeliveryControllerGetAllPaginated()` */
  static BandsDeliveryControllerGetAllPaginatedPath = '/v1/content-delivery-api/bands';
  /**
   * Get all bands paginated and ordered by points.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `bandsDeliveryControllerGetAllPaginated()` instead.
   *
   * This method doesn't expect any request body.
   */
  bandsDeliveryControllerGetAllPaginated$Response(params, context) {
    const rb = new RequestBuilder(this.rootUrl, BandmanagerRestApiAllBandsService.BandsDeliveryControllerGetAllPaginatedPath, 'get');
    if (params) {
      rb.query('countryId', params.countryId, {});
      rb.query('skip', params.skip, {});
      rb.query('take', params.take, {});
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context
    })).pipe(filter(r => r instanceof HttpResponse), map(r => {
      return r;
    }));
  }
  /**
   * Get all bands paginated and ordered by points.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `bandsDeliveryControllerGetAllPaginated$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  bandsDeliveryControllerGetAllPaginated(params, context) {
    return this.bandsDeliveryControllerGetAllPaginated$Response(params, context).pipe(map(r => r.body));
  }
  static ɵfac = function BandmanagerRestApiAllBandsService_Factory(t) {
    return new (t || BandmanagerRestApiAllBandsService)(i0.ɵɵinject(i1.ApiConfiguration), i0.ɵɵinject(i2.HttpClient));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: BandmanagerRestApiAllBandsService,
    factory: BandmanagerRestApiAllBandsService.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BandmanagerRestApiAllBandsService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1.ApiConfiguration
  }, {
    type: i2.HttpClient
  }], null);
})();

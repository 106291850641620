/* tslint:disable */
/* eslint-disable */
export var BandmanagerRestApiSkillTypeEnum;
(function (BandmanagerRestApiSkillTypeEnum) {
  BandmanagerRestApiSkillTypeEnum["Tuning"] = "Tuning";
  BandmanagerRestApiSkillTypeEnum["Range"] = "Range";
  BandmanagerRestApiSkillTypeEnum["Technique"] = "Technique";
  BandmanagerRestApiSkillTypeEnum["Virtuosity"] = "Virtuosity";
  BandmanagerRestApiSkillTypeEnum["SoundQuality"] = "SoundQuality";
  BandmanagerRestApiSkillTypeEnum["Precision"] = "Precision";
  BandmanagerRestApiSkillTypeEnum["Confidence"] = "Confidence";
})(BandmanagerRestApiSkillTypeEnum || (BandmanagerRestApiSkillTypeEnum = {}));

/* tslint:disable */
/* eslint-disable */
import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { filter, map } from 'rxjs/operators';
import { BaseService } from '../base-service';
import { RequestBuilder } from '../request-builder';
import * as i0 from "@angular/core";
import * as i1 from "../api-configuration";
import * as i2 from "@angular/common/http";
export class BandmanagerRestApiAuthenticationService extends BaseService {
  constructor(config, http) {
    super(config, http);
  }
  /** Path part for operation `authenticationControllerLogin()` */
  static AuthenticationControllerLoginPath = '/v1/authentication/login';
  /**
   * Login using e-mail and password.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authenticationControllerLogin()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authenticationControllerLogin$Response(params, context) {
    const rb = new RequestBuilder(this.rootUrl, BandmanagerRestApiAuthenticationService.AuthenticationControllerLoginPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context
    })).pipe(filter(r => r instanceof HttpResponse), map(r => {
      return r;
    }));
  }
  /**
   * Login using e-mail and password.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `authenticationControllerLogin$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authenticationControllerLogin(params, context) {
    return this.authenticationControllerLogin$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `authenticationControllerVerify()` */
  static AuthenticationControllerVerifyPath = '/v1/authentication/verify';
  /**
   * Verify account with user id and token.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authenticationControllerVerify()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authenticationControllerVerify$Response(params, context) {
    const rb = new RequestBuilder(this.rootUrl, BandmanagerRestApiAuthenticationService.AuthenticationControllerVerifyPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context
    })).pipe(filter(r => r instanceof HttpResponse), map(r => {
      return r;
    }));
  }
  /**
   * Verify account with user id and token.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `authenticationControllerVerify$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authenticationControllerVerify(params, context) {
    return this.authenticationControllerVerify$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `authenticationControllerRegister()` */
  static AuthenticationControllerRegisterPath = '/v1/authentication/register';
  /**
   * Create account with email and password.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authenticationControllerRegister()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authenticationControllerRegister$Response(params, context) {
    const rb = new RequestBuilder(this.rootUrl, BandmanagerRestApiAuthenticationService.AuthenticationControllerRegisterPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context
    })).pipe(filter(r => r instanceof HttpResponse), map(r => {
      return r;
    }));
  }
  /**
   * Create account with email and password.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `authenticationControllerRegister$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authenticationControllerRegister(params, context) {
    return this.authenticationControllerRegister$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `authenticationControllerPasswordResetRequest()` */
  static AuthenticationControllerPasswordResetRequestPath = '/v1/authentication/password-reset-request';
  /**
   * Request e-mail with password reset link in it.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authenticationControllerPasswordResetRequest()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authenticationControllerPasswordResetRequest$Response(params, context) {
    const rb = new RequestBuilder(this.rootUrl, BandmanagerRestApiAuthenticationService.AuthenticationControllerPasswordResetRequestPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context
    })).pipe(filter(r => r instanceof HttpResponse), map(r => {
      return r;
    }));
  }
  /**
   * Request e-mail with password reset link in it.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `authenticationControllerPasswordResetRequest$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authenticationControllerPasswordResetRequest(params, context) {
    return this.authenticationControllerPasswordResetRequest$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `authenticationControllerPasswordReset()` */
  static AuthenticationControllerPasswordResetPath = '/v1/authentication/password-reset';
  /**
   * Set new password with token.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authenticationControllerPasswordReset()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authenticationControllerPasswordReset$Response(params, context) {
    const rb = new RequestBuilder(this.rootUrl, BandmanagerRestApiAuthenticationService.AuthenticationControllerPasswordResetPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context
    })).pipe(filter(r => r instanceof HttpResponse), map(r => {
      return r;
    }));
  }
  /**
   * Set new password with token.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `authenticationControllerPasswordReset$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authenticationControllerPasswordReset(params, context) {
    return this.authenticationControllerPasswordReset$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `authenticationControllerRefreshToken()` */
  static AuthenticationControllerRefreshTokenPath = '/v1/authentication/refreshTokens';
  /**
   * Get new tokens with refresh token.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authenticationControllerRefreshToken()` instead.
   *
   * This method doesn't expect any request body.
   */
  authenticationControllerRefreshToken$Response(params, context) {
    const rb = new RequestBuilder(this.rootUrl, BandmanagerRestApiAuthenticationService.AuthenticationControllerRefreshTokenPath, 'post');
    if (params) {}
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context
    })).pipe(filter(r => r instanceof HttpResponse), map(r => {
      return r;
    }));
  }
  /**
   * Get new tokens with refresh token.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `authenticationControllerRefreshToken$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  authenticationControllerRefreshToken(params, context) {
    return this.authenticationControllerRefreshToken$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `authenticationControllerLogout()` */
  static AuthenticationControllerLogoutPath = '/v1/authentication/logout';
  /**
   * Do logout.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authenticationControllerLogout()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authenticationControllerLogout$Response(params, context) {
    const rb = new RequestBuilder(this.rootUrl, BandmanagerRestApiAuthenticationService.AuthenticationControllerLogoutPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context
    })).pipe(filter(r => r instanceof HttpResponse), map(r => {
      return r.clone({
        body: undefined
      });
    }));
  }
  /**
   * Do logout.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `authenticationControllerLogout$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authenticationControllerLogout(params, context) {
    return this.authenticationControllerLogout$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `authenticationControllerLogoutFromAllDevices()` */
  static AuthenticationControllerLogoutFromAllDevicesPath = '/v1/authentication/logoutFromAllDevices';
  /**
   * Do logout.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authenticationControllerLogoutFromAllDevices()` instead.
   *
   * This method doesn't expect any request body.
   */
  authenticationControllerLogoutFromAllDevices$Response(params, context) {
    const rb = new RequestBuilder(this.rootUrl, BandmanagerRestApiAuthenticationService.AuthenticationControllerLogoutFromAllDevicesPath, 'post');
    if (params) {}
    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context
    })).pipe(filter(r => r instanceof HttpResponse), map(r => {
      return r.clone({
        body: undefined
      });
    }));
  }
  /**
   * Do logout.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `authenticationControllerLogoutFromAllDevices$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  authenticationControllerLogoutFromAllDevices(params, context) {
    return this.authenticationControllerLogoutFromAllDevices$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `authenticationControllerProfile()` */
  static AuthenticationControllerProfilePath = '/v1/authentication/profile';
  /**
   * Get user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authenticationControllerProfile()` instead.
   *
   * This method doesn't expect any request body.
   */
  authenticationControllerProfile$Response(params, context) {
    const rb = new RequestBuilder(this.rootUrl, BandmanagerRestApiAuthenticationService.AuthenticationControllerProfilePath, 'get');
    if (params) {}
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context
    })).pipe(filter(r => r instanceof HttpResponse), map(r => {
      return r;
    }));
  }
  /**
   * Get user.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `authenticationControllerProfile$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  authenticationControllerProfile(params, context) {
    return this.authenticationControllerProfile$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `authenticationControllerDeleteUserProfile()` */
  static AuthenticationControllerDeleteUserProfilePath = '/v1/authentication/profile';
  /**
   * Delete the user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authenticationControllerDeleteUserProfile()` instead.
   *
   * This method doesn't expect any request body.
   */
  authenticationControllerDeleteUserProfile$Response(params, context) {
    const rb = new RequestBuilder(this.rootUrl, BandmanagerRestApiAuthenticationService.AuthenticationControllerDeleteUserProfilePath, 'delete');
    if (params) {}
    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context
    })).pipe(filter(r => r instanceof HttpResponse), map(r => {
      return r.clone({
        body: undefined
      });
    }));
  }
  /**
   * Delete the user.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `authenticationControllerDeleteUserProfile$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  authenticationControllerDeleteUserProfile(params, context) {
    return this.authenticationControllerDeleteUserProfile$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `authenticationControllerUpdateUserProfile()` */
  static AuthenticationControllerUpdateUserProfilePath = '/v1/authentication/profile';
  /**
   * Update user, e.g. used for setting the username.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authenticationControllerUpdateUserProfile()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authenticationControllerUpdateUserProfile$Response(params, context) {
    const rb = new RequestBuilder(this.rootUrl, BandmanagerRestApiAuthenticationService.AuthenticationControllerUpdateUserProfilePath, 'patch');
    if (params) {
      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context
    })).pipe(filter(r => r instanceof HttpResponse), map(r => {
      return r;
    }));
  }
  /**
   * Update user, e.g. used for setting the username.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `authenticationControllerUpdateUserProfile$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authenticationControllerUpdateUserProfile(params, context) {
    return this.authenticationControllerUpdateUserProfile$Response(params, context).pipe(map(r => r.body));
  }
  static ɵfac = function BandmanagerRestApiAuthenticationService_Factory(t) {
    return new (t || BandmanagerRestApiAuthenticationService)(i0.ɵɵinject(i1.ApiConfiguration), i0.ɵɵinject(i2.HttpClient));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: BandmanagerRestApiAuthenticationService,
    factory: BandmanagerRestApiAuthenticationService.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BandmanagerRestApiAuthenticationService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1.ApiConfiguration
  }, {
    type: i2.HttpClient
  }], null);
})();

/* tslint:disable */
/* eslint-disable */
export var BandmanagerRestApiBandSectionEnum;
(function (BandmanagerRestApiBandSectionEnum) {
  BandmanagerRestApiBandSectionEnum["Unassigned"] = "Unassigned";
  BandmanagerRestApiBandSectionEnum["Conductor"] = "Conductor";
  BandmanagerRestApiBandSectionEnum["FrontRowCornets"] = "FrontRowCornets";
  BandmanagerRestApiBandSectionEnum["BackRowCornets"] = "BackRowCornets";
  BandmanagerRestApiBandSectionEnum["Flugelhorn"] = "Flugelhorn";
  BandmanagerRestApiBandSectionEnum["Horns"] = "Horns";
  BandmanagerRestApiBandSectionEnum["Baritones"] = "Baritones";
  BandmanagerRestApiBandSectionEnum["Euphoniums"] = "Euphoniums";
  BandmanagerRestApiBandSectionEnum["Trombones"] = "Trombones";
  BandmanagerRestApiBandSectionEnum["Tubas"] = "Tubas";
  BandmanagerRestApiBandSectionEnum["Percussion"] = "Percussion";
})(BandmanagerRestApiBandSectionEnum || (BandmanagerRestApiBandSectionEnum = {}));

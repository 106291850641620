/* tslint:disable */
/* eslint-disable */
import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { filter, map } from 'rxjs/operators';
import { BaseService } from '../base-service';
import { RequestBuilder } from '../request-builder';
import * as i0 from "@angular/core";
import * as i1 from "../api-configuration";
import * as i2 from "@angular/common/http";
export class BandmanagerRestApiAppleService extends BaseService {
  constructor(config, http) {
    super(config, http);
  }
  /** Path part for operation `appleControllerLogin()` */
  static AppleControllerLoginPath = '/v1/apple';
  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `appleControllerLogin()` instead.
   *
   * This method doesn't expect any request body.
   */
  appleControllerLogin$Response(params, context) {
    const rb = new RequestBuilder(this.rootUrl, BandmanagerRestApiAppleService.AppleControllerLoginPath, 'get');
    if (params) {}
    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context
    })).pipe(filter(r => r instanceof HttpResponse), map(r => {
      return r.clone({
        body: undefined
      });
    }));
  }
  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `appleControllerLogin$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  appleControllerLogin(params, context) {
    return this.appleControllerLogin$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `appleControllerServerToServerNotifications()` */
  static AppleControllerServerToServerNotificationsPath = '/v1/apple/server-to-server-notifications';
  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `appleControllerServerToServerNotifications()` instead.
   *
   * This method doesn't expect any request body.
   */
  appleControllerServerToServerNotifications$Response(params, context) {
    const rb = new RequestBuilder(this.rootUrl, BandmanagerRestApiAppleService.AppleControllerServerToServerNotificationsPath, 'post');
    if (params) {}
    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context
    })).pipe(filter(r => r instanceof HttpResponse), map(r => {
      return r.clone({
        body: undefined
      });
    }));
  }
  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `appleControllerServerToServerNotifications$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  appleControllerServerToServerNotifications(params, context) {
    return this.appleControllerServerToServerNotifications$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `appleControllerRedirect()` */
  static AppleControllerRedirectPath = '/v1/apple/redirect';
  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `appleControllerRedirect()` instead.
   *
   * This method doesn't expect any request body.
   */
  appleControllerRedirect$Response(params, context) {
    const rb = new RequestBuilder(this.rootUrl, BandmanagerRestApiAppleService.AppleControllerRedirectPath, 'post');
    if (params) {}
    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context
    })).pipe(filter(r => r instanceof HttpResponse), map(r => {
      return r.clone({
        body: undefined
      });
    }));
  }
  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `appleControllerRedirect$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  appleControllerRedirect(params, context) {
    return this.appleControllerRedirect$Response(params, context).pipe(map(r => r.body));
  }
  static ɵfac = function BandmanagerRestApiAppleService_Factory(t) {
    return new (t || BandmanagerRestApiAppleService)(i0.ɵɵinject(i1.ApiConfiguration), i0.ɵɵinject(i2.HttpClient));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: BandmanagerRestApiAppleService,
    factory: BandmanagerRestApiAppleService.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BandmanagerRestApiAppleService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1.ApiConfiguration
  }, {
    type: i2.HttpClient
  }], null);
})();

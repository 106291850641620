/* tslint:disable */
/* eslint-disable */
import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { filter, map } from 'rxjs/operators';
import { BaseService } from '../base-service';
import { RequestBuilder } from '../request-builder';
import * as i0 from "@angular/core";
import * as i1 from "../api-configuration";
import * as i2 from "@angular/common/http";
export class BandmanagerRestApiAllCountriesService extends BaseService {
  constructor(config, http) {
    super(config, http);
  }
  /** Path part for operation `countriesDeliveryControllerGetAll()` */
  static CountriesDeliveryControllerGetAllPath = '/v1/content-delivery-api/countries';
  /**
   * Get all countries.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `countriesDeliveryControllerGetAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  countriesDeliveryControllerGetAll$Response(params, context) {
    const rb = new RequestBuilder(this.rootUrl, BandmanagerRestApiAllCountriesService.CountriesDeliveryControllerGetAllPath, 'get');
    if (params) {
      rb.query('havingBands', params.havingBands, {});
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context
    })).pipe(filter(r => r instanceof HttpResponse), map(r => {
      return r;
    }));
  }
  /**
   * Get all countries.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `countriesDeliveryControllerGetAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  countriesDeliveryControllerGetAll(params, context) {
    return this.countriesDeliveryControllerGetAll$Response(params, context).pipe(map(r => r.body));
  }
  static ɵfac = function BandmanagerRestApiAllCountriesService_Factory(t) {
    return new (t || BandmanagerRestApiAllCountriesService)(i0.ɵɵinject(i1.ApiConfiguration), i0.ɵɵinject(i2.HttpClient));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: BandmanagerRestApiAllCountriesService,
    factory: BandmanagerRestApiAllCountriesService.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BandmanagerRestApiAllCountriesService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1.ApiConfiguration
  }, {
    type: i2.HttpClient
  }], null);
})();

/* tslint:disable */
/* eslint-disable */
import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { filter, map } from 'rxjs/operators';
import { BaseService } from '../base-service';
import { RequestBuilder } from '../request-builder';
import * as i0 from "@angular/core";
import * as i1 from "../api-configuration";
import * as i2 from "@angular/common/http";
export class BandmanagerRestApiAllEventsService extends BaseService {
  constructor(config, http) {
    super(config, http);
  }
  /** Path part for operation `eventsDeliveryControllerGetById()` */
  static EventsDeliveryControllerGetByIdPath = '/v1/content-delivery-api/events/{id}';
  /**
   * Get a specific event by ID.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `eventsDeliveryControllerGetById()` instead.
   *
   * This method doesn't expect any request body.
   */
  eventsDeliveryControllerGetById$Response(params, context) {
    const rb = new RequestBuilder(this.rootUrl, BandmanagerRestApiAllEventsService.EventsDeliveryControllerGetByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context
    })).pipe(filter(r => r instanceof HttpResponse), map(r => {
      return r;
    }));
  }
  /**
   * Get a specific event by ID.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `eventsDeliveryControllerGetById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  eventsDeliveryControllerGetById(params, context) {
    return this.eventsDeliveryControllerGetById$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `eventsDeliveryControllerGetAllFutureOnesPaginated()` */
  static EventsDeliveryControllerGetAllFutureOnesPaginatedPath = '/v1/content-delivery-api/events';
  /**
   * Get all events paginated order by date, only future or current ones.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `eventsDeliveryControllerGetAllFutureOnesPaginated()` instead.
   *
   * This method doesn't expect any request body.
   */
  eventsDeliveryControllerGetAllFutureOnesPaginated$Response(params, context) {
    const rb = new RequestBuilder(this.rootUrl, BandmanagerRestApiAllEventsService.EventsDeliveryControllerGetAllFutureOnesPaginatedPath, 'get');
    if (params) {
      rb.query('skip', params.skip, {});
      rb.query('take', params.take, {});
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context
    })).pipe(filter(r => r instanceof HttpResponse), map(r => {
      return r;
    }));
  }
  /**
   * Get all events paginated order by date, only future or current ones.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `eventsDeliveryControllerGetAllFutureOnesPaginated$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  eventsDeliveryControllerGetAllFutureOnesPaginated(params, context) {
    return this.eventsDeliveryControllerGetAllFutureOnesPaginated$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `eventLiveTickersControllerGetLiveFeedByEventId()` */
  static EventLiveTickersControllerGetLiveFeedByEventIdPath = '/v1/content-delivery-api/event-live-tickers/{id}/event-section/{eventSectionId}';
  /**
   * Get a live feed of an event.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `eventLiveTickersControllerGetLiveFeedByEventId()` instead.
   *
   * This method doesn't expect any request body.
   */
  eventLiveTickersControllerGetLiveFeedByEventId$Response(params, context) {
    const rb = new RequestBuilder(this.rootUrl, BandmanagerRestApiAllEventsService.EventLiveTickersControllerGetLiveFeedByEventIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('eventSectionId', params.eventSectionId, {});
      rb.query('languageCode', params.languageCode, {});
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context
    })).pipe(filter(r => r instanceof HttpResponse), map(r => {
      return r;
    }));
  }
  /**
   * Get a live feed of an event.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `eventLiveTickersControllerGetLiveFeedByEventId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  eventLiveTickersControllerGetLiveFeedByEventId(params, context) {
    return this.eventLiveTickersControllerGetLiveFeedByEventId$Response(params, context).pipe(map(r => r.body));
  }
  static ɵfac = function BandmanagerRestApiAllEventsService_Factory(t) {
    return new (t || BandmanagerRestApiAllEventsService)(i0.ɵɵinject(i1.ApiConfiguration), i0.ɵɵinject(i2.HttpClient));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: BandmanagerRestApiAllEventsService,
    factory: BandmanagerRestApiAllEventsService.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BandmanagerRestApiAllEventsService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1.ApiConfiguration
  }, {
    type: i2.HttpClient
  }], null);
})();

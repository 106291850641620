/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import * as i0 from "@angular/core";
import * as i1 from "./api-configuration";
import * as i2 from "@angular/common/http";
/**
 * Base class for services
 */
export class BaseService {
  config;
  http;
  constructor(config, http) {
    this.config = config;
    this.http = http;
  }
  _rootUrl = '';
  /**
   * Returns the root url for all operations in this service. If not set directly in this
   * service, will fallback to `ApiConfiguration.rootUrl`.
   */
  get rootUrl() {
    return this._rootUrl || this.config.rootUrl;
  }
  /**
   * Sets the root URL for API operations in this service.
   */
  set rootUrl(rootUrl) {
    this._rootUrl = rootUrl;
  }
  static ɵfac = function BaseService_Factory(t) {
    return new (t || BaseService)(i0.ɵɵinject(i1.ApiConfiguration), i0.ɵɵinject(i2.HttpClient));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: BaseService,
    factory: BaseService.ɵfac
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BaseService, [{
    type: Injectable
  }], () => [{
    type: i1.ApiConfiguration
  }, {
    type: i2.HttpClient
  }], null);
})();

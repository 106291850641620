/* tslint:disable */
/* eslint-disable */
export var BandmanagerRestApiEventPriceTypeEnum;
(function (BandmanagerRestApiEventPriceTypeEnum) {
  BandmanagerRestApiEventPriceTypeEnum["FirstPlace"] = "FirstPlace";
  BandmanagerRestApiEventPriceTypeEnum["SecondPlace"] = "SecondPlace";
  BandmanagerRestApiEventPriceTypeEnum["ThirdPlace"] = "ThirdPlace";
  BandmanagerRestApiEventPriceTypeEnum["BestPrincipalCornet"] = "BestPrincipalCornet";
  BandmanagerRestApiEventPriceTypeEnum["BestSopranoCornet"] = "BestSopranoCornet";
  BandmanagerRestApiEventPriceTypeEnum["BestRepianoCornet"] = "BestRepianoCornet";
  BandmanagerRestApiEventPriceTypeEnum["BestFrontrow"] = "BestFrontrow";
  BandmanagerRestApiEventPriceTypeEnum["BestBackrow"] = "BestBackrow";
  BandmanagerRestApiEventPriceTypeEnum["BestFlugelhorn"] = "BestFlugelhorn";
  BandmanagerRestApiEventPriceTypeEnum["BestSolohorn"] = "BestSolohorn";
  BandmanagerRestApiEventPriceTypeEnum["BestHornSection"] = "BestHornSection";
  BandmanagerRestApiEventPriceTypeEnum["BestSoloEuphonium"] = "BestSoloEuphonium";
  BandmanagerRestApiEventPriceTypeEnum["BestSoloBaritone"] = "BestSoloBaritone";
  BandmanagerRestApiEventPriceTypeEnum["BestSoloTrombone"] = "BestSoloTrombone";
  BandmanagerRestApiEventPriceTypeEnum["BestTromboneSection"] = "BestTromboneSection";
  BandmanagerRestApiEventPriceTypeEnum["BestSoloEbBass"] = "BestSoloEbBass";
  BandmanagerRestApiEventPriceTypeEnum["BestSoloBbBass"] = "BestSoloBbBass";
  BandmanagerRestApiEventPriceTypeEnum["BestPercussionSection"] = "BestPercussionSection";
})(BandmanagerRestApiEventPriceTypeEnum || (BandmanagerRestApiEventPriceTypeEnum = {}));

/* tslint:disable */
/* eslint-disable */
import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { filter, map } from 'rxjs/operators';
import { BaseService } from '../base-service';
import { RequestBuilder } from '../request-builder';
import * as i0 from "@angular/core";
import * as i1 from "../api-configuration";
import * as i2 from "@angular/common/http";
export class BandmanagerRestApiMyDevicesService extends BaseService {
  constructor(config, http) {
    super(config, http);
  }
  /** Path part for operation `userDevicesControllerGetMyDevices()` */
  static UserDevicesControllerGetMyDevicesPath = '/v1/content-management-api/user-devices';
  /**
   * Get my devices.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userDevicesControllerGetMyDevices()` instead.
   *
   * This method doesn't expect any request body.
   */
  userDevicesControllerGetMyDevices$Response(params, context) {
    const rb = new RequestBuilder(this.rootUrl, BandmanagerRestApiMyDevicesService.UserDevicesControllerGetMyDevicesPath, 'get');
    if (params) {}
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context
    })).pipe(filter(r => r instanceof HttpResponse), map(r => {
      return r;
    }));
  }
  /**
   * Get my devices.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userDevicesControllerGetMyDevices$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userDevicesControllerGetMyDevices(params, context) {
    return this.userDevicesControllerGetMyDevices$Response(params, context).pipe(map(r => r.body));
  }
  /** Path part for operation `userDevicesControllerRegisterUserDevice()` */
  static UserDevicesControllerRegisterUserDevicePath = '/v1/content-management-api/user-devices';
  /**
   * Register new device.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userDevicesControllerRegisterUserDevice()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userDevicesControllerRegisterUserDevice$Response(params, context) {
    const rb = new RequestBuilder(this.rootUrl, BandmanagerRestApiMyDevicesService.UserDevicesControllerRegisterUserDevicePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context
    })).pipe(filter(r => r instanceof HttpResponse), map(r => {
      return r;
    }));
  }
  /**
   * Register new device.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userDevicesControllerRegisterUserDevice$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userDevicesControllerRegisterUserDevice(params, context) {
    return this.userDevicesControllerRegisterUserDevice$Response(params, context).pipe(map(r => r.body));
  }
  static ɵfac = function BandmanagerRestApiMyDevicesService_Factory(t) {
    return new (t || BandmanagerRestApiMyDevicesService)(i0.ɵɵinject(i1.ApiConfiguration), i0.ɵɵinject(i2.HttpClient));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: BandmanagerRestApiMyDevicesService,
    factory: BandmanagerRestApiMyDevicesService.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BandmanagerRestApiMyDevicesService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1.ApiConfiguration
  }, {
    type: i2.HttpClient
  }], null);
})();
